import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Heading from "../../../components/heading"
import HoelderlinLiebtNavigation from "../../../content/hoelderlin-liebt/navigation"
import Image from "../../../components/image"
import ImageFrame from "../../../components/image-frame"
import Layout from "../../../layouts/default"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import SectionSpacing from "../../../components/section-spacing"
import Stack from "../../../components/stack"
import Constrain from "../../../components/constrain"
import ReadMore from "../../../components/read-more"

const HoelderlinLiebtSusette = () => {
  const data = useStaticQuery(
    graphql`
      {
        ogImage: file(relativePath: { eq: "og_images/hoelderlin-liebt.jpg" }) {
          id
          childImageSharp {
            gatsbyImageData(width: 1200, height: 600, layout: FIXED)
          }
        }
        titelbild: file(
          relativePath: { eq: "hoelderlin-liebt/einleitung-susette.jpg" }
        ) {
          ...mediumImage
        }
        matheheft: file(
          relativePath: { eq: "leichte-sprache/matheheft-henry-gontard.png" }
        ) {
          ...largeImage
        }
        brief: file(
          relativePath: { eq: "leichte-sprache/brief-susette-gontard.png" }
        ) {
          ...largeImage
        }
        widmung: file(
          relativePath: {
            eq: "dauerausstellung/07-harte-fuegungen/hyperion-widmung.png"
          }
        ) {
          ...largeImage
        }
      }
    `
  )

  return (
    <Layout backdrop="hoelderlin-liebt">
      <Seo
        title="Hölderlin liebt Susette Gontard"
        description="Als Hauslehrer unterrichtet Hölderlin auch in Frankfurt.  Dort wohnt er bei der Familie Gontard.  Und er verliebt sich in die Frau seines Arbeitgebers: Susette Gontard."
        image={data.ogImage.childImageSharp.gatsbyImageData}
      />
      <SectionSpacing>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Digital",
                link: "/digital",
              },
              {
                title: "Sonderausstellungen",
                link: "/sonderausstellungen",
              },
              {
                title: "Hölderlin liebt",
                link: "/sonderausstellungen/hoelderlin-liebt",
              },
              {
                title: "… Susette Gontard",
                link: "/sonderausstellungen/hoelderlin-liebt/susette",
              },
            ]}
          />
          <PageTitle>Hölderlin liebt Susette Gontard</PageTitle>
          <Grid columns={[1, 2]} alignY="center">
            <Paragraph dropcap={true}>
              Als Hauslehrer unterrichtet Hölderlin auch in Frankfurt. <br />
              Dort wohnt er bei der Familie Gontard. <br />
              Und er verliebt sich in die Frau seines Arbeitgebers: <br />
              Susette Gontard.
            </Paragraph>
            <ImageFrame>
              <Image
                image={data.titelbild.childImageSharp.gatsbyImageData}
                alt="Eine Illustration zeigt Susette Gontard. Sie hat ihre Haare zusammengeknotet und hält ein Buch in der Hand."
              />
            </ImageFrame>
          </Grid>
        </Stack>

        <Stack>
          <Paragraph>
            In das Matheheft von seinem Schüler Henry Gontard <br />
            notiert Hölderlin auch Ideen zu einem Theaterstück. <br />
            Es heißt:{" "}
            <ReadMore
              content={
                <Constrain>
                  <Stack>
                    <Heading as="h3" level={3}>
                      ›Der Tod des Empedokles‹
                    </Heading>
                    <Paragraph>
                      ›Der Tod des Empedokles‹ ist ein Theaterstück, genauer:{" "}
                      <br />
                      Ein Theaterstück mit einem schlimmen Ende. <br />
                      Wir nennen ein solches Stück eine Tragödie.
                      <br />
                      Empedokles ist ein Grieche.
                      <br />
                      Und er ist ein mutiger Anführer.
                      <br />
                      In dem Theaterstück geht es um die Freiheit der Menschen.
                      <br />
                      Empedokles schützt seine Familie, <br />
                      seine Nachbarn und Freunde vor Feinden und anderen
                      Gefahren.
                      <br />
                      Alle möchten ihn zu ihrem König machen.
                      <br />
                      Aber Empedokles will das nicht.
                    </Paragraph>
                    <Paragraph>
                      Er sagt: <br />
                      Menschen müssen frei sein. <br />
                      Sie dürfen keinen König haben. <br />
                      Er steigt auf einen Berg <br />
                      und stürzt sich von dort in den Abgrund. <br />
                      Er will kein König sein. <br />
                      Er ist lieber tot.
                    </Paragraph>
                  </Stack>
                </Constrain>
              }
            >
              ›Der Tod des Empedokles‹
            </ReadMore>
            .
          </Paragraph>
          <Constrain align="right">
            <Image
              image={data.matheheft.childImageSharp.gatsbyImageData}
              caption="Ein Schulheft von Hölderlins Schüler Henry Gontard. Aus der Württembergischen Landesbibliothek Stuttgart."
              alt="Ein Heft mit vielen Notizen. Die linke Seite ist mit Text vollgeschrieben. Auf der rechten Seite stehen mathematische Formeln."
            />
          </Constrain>
        </Stack>

        <Stack>
          <Paragraph>
            Als Hölderlin Susette Gontard kennenlernt, schreibt er gerade an
            einem Buch: <br />
            Es heißt{" "}
            <ReadMore
              content={
                <Constrain>
                  <Stack>
                    <Heading as="h3" level={3}>
                      ›Hyperion‹
                    </Heading>
                    <Paragraph>
                      Schon als Kind sagt Hölderlin: <br />
                      Die Welt ist so schön, darüber möchte ich schreiben.{" "}
                      <br />
                      Später sagt er: <br />
                      Der Mensch muss frei sein. <br />
                      Über die Freiheit möchte ich auch schreiben. <br />
                      Aber das ist damals verboten. <br />
                      Hölderlin hat eine Idee. <br />
                      Er schreibt einen Roman über einen jungen Mann in
                      Griechenland. <br />
                      Er nennt ihn Hyperion. <br />
                      Im Buch kämpft Hyperion für die Freiheit. <br />
                      Über das Leben eines anderen Menschen zu schreiben <br />
                      ist damals nicht verboten.
                    </Paragraph>
                  </Stack>
                </Constrain>
              }
            >
              ›Hyperion‹
            </ReadMore>
            . <br />
            Mit Susette spricht Hölderlin am liebsten über sein Buch. <br />
            Sie ist so klug und so schön. <br />
            Sie erinnert ihn an seine Romanfigur Diotima. <br />
            Es kommt ihm vor, als hätte er sie schon lange gekannt. <br />
            Zum Schluss widmet Hölderlin das Buch seiner Freundin. <br />
            »Wem sonst als Dir« schreibt er dort hinein.
          </Paragraph>
          <Constrain align="right">
            <Image
              image={data.widmung.childImageSharp.gatsbyImageData}
              alt="Hölderlins Buch ›Hyperion‹. Die rechte Seite zeigt eine handschriftliche Widmung von Hölderlin: »Wem sonst als dir«"
              caption="Hölderlins ›Hyperion‹ von 1799 mit einer versteckten Widmung für Susette Gontard aus dem Deutschen Literaturarchiv Marbach"
            />
          </Constrain>
        </Stack>

        <Stack>
          <Stack space={6}>
            <Heading as="h2" level={3}>
              Geheime Briefe
            </Heading>
            <Paragraph>
              Hölderlin und Susette haben eine heimliche Liebesbeziehung. <br />
              Doch Susette ist verheiratet. <br />
              Die beiden müssen sich trennen. <br />
              Aber sie können sich noch Briefe schreiben. <br />
              Hölderlin trägt die 17 Briefe von Susette immer bei sich. <br />
              Sogar bis nach Frankreich. <br />
              Noch im Turm hat er die Briefe bei sich.
            </Paragraph>
          </Stack>

          <Constrain align="right">
            <Image
              image={data.brief.childImageSharp.gatsbyImageData}
              caption="Ein Liebesbrief von Susette Gontard an Hölderlin."
              alt="Der Brief zeigt die schöne, geschwungene Handschrift von Susette Gontard."
            />
          </Constrain>
        </Stack>

        <HoelderlinLiebtNavigation />
      </SectionSpacing>
    </Layout>
  )
}

export default HoelderlinLiebtSusette
