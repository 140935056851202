import React from "react"
import Box from "./box"
import PropTypes from "prop-types"

const ImageFrame = ({ children }) => {
  return (
    <Box
      sx={{
        borderWidth: 8,
        borderStyle: "solid",
        borderColor: "border",
      }}
    >
      {children}
    </Box>
  )
}

ImageFrame.propTypes = {
  children: PropTypes.node,
}

export default ImageFrame
